var modal1 = document.getElementById('zvonok');
var modal2 = document.getElementById('zayavka');
let modal3 = document.getElementById('citys');
let modal4 = document.getElementById('call-page');
let salemodal = document.querySelector('#salemodal');

window.onclick = function(event) {
  if (event.target == modal1) {
    modal1.style.display = "none";
  }

  if (event.target == modal3) {
    modal3.style.display = "none";
  }

  if (event.target == modal2) {
    modal2.style.display = "none";
  }

  if (event.target == modal4) {
    modal4.style.display = "none";
  }

  if (event.target === salemodal) {
    salemodal.style.display = "none";
  }
}

// ЗАКАЖИТЕ БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ
function submitEmailZ(params) {
  let str = window.atob("aHR0cHM6Ly9vb2NjbWFpbC5ydS9zZW5kP3Rva2VuPXZqOTgzNGhnMmhmNzg5aDIzNGZoNHVmaDM3OGZoMw==");

  fetch(`${str}`, {
    credentials: 'same-origin',
    mode:'no-cors',
    method: 'POST',

    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
    },

    body: `name=${params.name}&phone=${params.phone}&otpravlenie=${params.otpravlenie}&polycenie=${params.polycenie}&ves=${params.ves}&objom=${params.objom}`
  })
  .catch(error => console.log(error))
}

function validaionFormZ(e, form) {
  e.preventDefault();

  let name = form.children[0][0].value;
  let phone = form.children[0][1].value;

  let otpravlenie = document.querySelector('#otpravlenie').value;
  let polycenie = document.querySelector('#polycenie').value;
  let ves = document.querySelector('#slider-ves').value;
  let objom = document.querySelector('#slider-objom').value;

  submitEmailZ({ name: name, phone: phone, otpravlenie: otpravlenie, polycenie: polycenie, ves: ves, objom: objom});

  form.children[0][0].value = "";
  form.children[0][1].value = "";

  let modal = document.querySelector("#zvonok");
  modal.style.display = "none";

  alert( "Успешно отправлено!" );
}

// modal form
let modalFormZ = document.querySelector('#zvonok');
modalFormZ.addEventListener('submit', e => validaionFormZ(e, modalFormZ));


